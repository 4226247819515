import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import LandingPage from './pages/LandingPage';
import PersistLogin from './features/auth/PersistLogin';
import SignupPageOne from './pages/SignupPageOne';
import SignupPageTwo from './pages/SignupPageTwo';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import MainPage from './pages/MainPage';
import ApproveOrderPage from './pages/ApproveOrderPage';
import IncompleteSignUpPage from './pages/IncompleteSignUpPage';
import EditAgentPage from './pages/EditAgentPage';
import AwaitingApprovalPage from './pages/AwaitingApprovalPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="signup-page-one" element={<SignupPageOne />} />
          <Route path="signup-page-two/:id" element={<SignupPageTwo />} />
          <Route path="incomplete-reg" element={<IncompleteSignUpPage />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password/:token" element={<ResetPassword />} />
          <Route
            path="agent-approval-page"
            element={<AwaitingApprovalPage />}
          />
          <Route element={<PersistLogin />}>
            <Route path="agent-main-page" element={<MainPage />} />
            {<Route path="order-details/:id" element={<ApproveOrderPage />} />}
            <Route path="edit-agent/:id" element={<EditAgentPage />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
