/* eslint-disable no-unused-vars */
import React from 'react';
import Footer from '../components/Footer';
import CustomInputy from '../components/CustomInputTwo';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Meta from '../components/Meta';
import { Select } from 'antd';
import SpinerComponent from '../components/SpinerComponent';
import { useGetAgentsQuery } from '../features/agents/agentsApiSlice';
import { useUpdateAgentMutation } from '../features/agents/agentsApiSlice';
import { Navbar } from '../components/Navbar';
import { toast } from 'react-toastify';

let schema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  acompany: Yup.string().required('Company is required'),
  adepartment: Yup.string().required('Department is required'),
  amobile: Yup.string().required('mobile number is required'),
});

const SignupPageTwo = () => {
  const navigate = useNavigate();
  const [showSpina, setShowSpina] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [firstnamee, setFirstname] = useState(null);

  const { id } = useParams();

  const { agent } = useGetAgentsQuery('agentsList', {
    selectFromResult: ({ data }) => ({
      agent: data?.entities[id],
    }),
  });

  const [updateAgent, { isLoading, isSuccess, error }] =
    useUpdateAgentMutation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }

    if (isSuccess) {
      sendEmailToAgent();
      setShowSpina(false);

      localStorage.setItem('aid', agent?.id);
      navigate(`/agent-approval-page`);
    }
    if (error) {
      setShowSpina(false);
      setShowErr(true);
      toast.error('Something went wrong!');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, error]);

  const sendEmailToAgent = async () => {
    try {
      const email = agent?.email;
      const firstname = firstnamee;
      const formData = new FormData();
      formData.append('firstname', firstname);
      formData.append('email', email);
      const urlEncoded = new URLSearchParams(formData).toString();
      await fetch(
        'https://worknshup-agent-backend-029506a667cd.herokuapp.com/api/agent/send-email',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
          },
          body: urlEncoded,
        }
      ).catch((err) => console.log(`Error occured: ${err}`));
    } catch (err) {
      console.log('error: email not sent!');
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      acompany: '',
      adepartment: '',
      amobile: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const { first_name, last_name, acompany, adepartment, amobile } = values;

      const firstname = first_name;
      const lastname = last_name;
      const company = acompany;
      const department = adepartment;
      const mobile = amobile;
      const id = agent._id;
      const registrationCompleted = true;

      try {
        await updateAgent({
          id,
          firstname,
          lastname,
          company,
          department,
          mobile,
          registrationCompleted,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  });

  return (
    <>
      <Meta title="Sign-Up-Two" />
      <Navbar />

      <section className="home-wrapper p-0 m-0">
        <div className="container-fluid py-1 ">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <form
                id="signtwoform"
                className="d-flex flex-column align-items-center gap-2 pb-2"
                action=""
                onSubmit={formik.handleSubmit}
              >
                <h2 className="pb-3">Agent details</h2>

                <CustomInputy
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder={'First Name'}
                  val={formik.values.first_name}
                  onCh={(e) => {
                    setFirstname(e.target.value);
                    formik.handleChange(e);
                  }}
                />
                <div className="error">
                  {formik.touched.first_name && formik.errors.first_name ? (
                    <div>{formik.errors.first_name}</div>
                  ) : null}
                </div>
                <CustomInputy
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder={'Last Name'}
                  val={formik.values.last_name}
                  onCh={formik.handleChange('last_name')}
                />
                <div className="error">
                  {formik.touched.last_name && formik.errors.last_name ? (
                    <div>{formik.errors.last_name}</div>
                  ) : null}
                </div>

                <Select
                  className="select-cat"
                  showSearch
                  placeholder="Select Your Company"
                  optionFilterProp="children"
                  onChange={formik.handleChange('acompany')}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'ADMINISTRATION',
                      label: 'ADMINISTRATION',
                    },
                    {
                      value: 'ENGINEERING',
                      label: 'ENGINEERING',
                    },
                    {
                      value: 'PRODUCTION',
                      label: 'PRODUCTION',
                    },
                    {
                      value: 'QUALITY ASSURANCE',
                      label: 'QUALITY ASSURANCE',
                    },
                    {
                      value: 'WAREHOUSE',
                      label: 'WAREHOUSE',
                    },
                    {
                      value: 'PURCHASING',
                      label: 'PURCHASING',
                    },
                    {
                      value: 'I.T',
                      label: 'I.T',
                    },
                    {
                      value: 'OPERATIONS',
                      label: 'OPERATIONS',
                    },
                    {
                      value: 'SALES AND MARKETING',
                      label: 'SALES AND MARKETING',
                    },
                    {
                      value: 'FINANCE AND ADMINISTRATION',
                      label: 'FINANCE AND ADMINISTRATION',
                    },
                    {
                      value: 'FIELD OPERATIONS',
                      label: 'FIELD OPERATIONS',
                    },
                    {
                      value: 'OTHERS',
                      label: 'OTHERS',
                    },
                  ]}
                />
                <div className="error">
                  {formik.touched.company && formik.errors.company}
                </div>

                <Select
                  className="select-cat"
                  showSearch
                  placeholder="Your Department"
                  optionFilterProp="children"
                  onChange={formik.handleChange('adepartment')}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'ADMINISTRATION',
                      label: 'ADMINISTRATION',
                    },
                    {
                      value: 'ENGINEERING',
                      label: 'ENGINEERING',
                    },
                    {
                      value: 'PRODUCTION',
                      label: 'PRODUCTION',
                    },
                    {
                      value: 'QUALITY ASSURANCE',
                      label: 'QUALITY ASSURANCE',
                    },
                    {
                      value: 'WAREHOUSE',
                      label: 'WAREHOUSE',
                    },
                    {
                      value: 'PURCHASING',
                      label: 'PURCHASING',
                    },
                    {
                      value: 'I.T',
                      label: 'I.T',
                    },
                    {
                      value: 'OTHERS',
                      label: 'OTHERS',
                    },
                  ]}
                />
                <div className="error">
                  {formik.touched.adepartment && formik.errors.adepartment ? (
                    <div>{formik.errors.adepartment}</div>
                  ) : null}
                </div>

                <CustomInputy
                  type="text"
                  name="amobile"
                  id="amobile"
                  placeholder="Mobile number"
                  val={formik.values.amobile}
                  onCh={formik.handleChange('amobile')}
                />
                <div className="error">
                  {formik.touched.amobile && formik.errors.amobile ? (
                    <div>{formik.errors.amobile}</div>
                  ) : null}
                </div>

                <button
                  className="signupbtn border-0 px-3 py-2 text-white fw-bold text-center  mt-5 fs-5"
                  type="submit"
                >
                  next
                </button>
                {showSpina && <SpinerComponent />}
                {showErr && (
                  <p style={{ color: 'red' }}>Something went wrong!</p>
                )}
              </form>
            </div>
          </div>
          <br />
          <br />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default SignupPageTwo;
