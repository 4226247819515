/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useGetAgentOrdersQuery } from '../features/agents/agentsApiSlice';
import { useGetAgentsQuery } from '../features/agents/agentsApiSlice';
import { useNavigate, Link } from 'react-router-dom';

const AgentPendingOrders = () => {
  const agnt = JSON.parse(localStorage.getItem('agent'));
  const aid = localStorage.getItem('aid');

  //fetxh all orders both agent's and for other companies
  const { data, isSuccess } = useGetAgentOrdersQuery('orderList', {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { agent } = useGetAgentsQuery('agentsList', {
    selectFromResult: ({ data }) => ({
      agent: data?.entities[aid],
    }),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (agnt === null) {
      navigate('/');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (agnt === null) {
      navigate('/');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agnt]);

  let content = (
    <div>
      <div className="contact-inner-wrapper d-flex justify-content-between">
        <div>
          <h3 className="contact-title mb-4">Orders</h3>
        </div>
      </div>
    </div>
  );

  try {
    if (isSuccess) {
      content = (
        <div className="container-fluid contact-inner-wrapper d-flex flex-column justify-content-between">
          <div>
            <h3 className="contact-title mb-4">Pending Orders</h3>
          </div>
          <div className="d-flex flex-column">
            <div className="row  w-100">
              {data?.agentOrder?.map((idy, index) => {
                if (idy?.user.company === agent?.company) {
                  if (idy?.orderStatus === 'PENDING') {
                    return (
                      <div className="col-12">
                        <Link
                          to={`/order-details/${idy?._id}`}
                          className="d-flex align-items-end"
                          style={{
                            borderRadius: '0.5rem',
                            textDecoration: 'none',
                            backgroundColor: 'white',
                            marginBottom: '0.5rem',
                            padding: '0.5rem 0.5rem 0.5rem 0.5rem',
                          }}
                        >
                          <div key={idy} className="row w-100">
                            <div className="col col-sm col-md">
                              {idy?.orderNumber}
                            </div>
                            <div className="col col-sm col-md">
                              {idy?.product?.title}
                            </div>

                            <div className="col col-sm col-md">
                              {idy?.user?.firstname}
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  }
                }
              })}
            </div>
          </div>
        </div>
      );
    }
  } catch (err) {
    content = <p>{err}</p>;
  }

  return content;
};

export default AgentPendingOrders;
