/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SpinerComponent from '../components/SpinerComponent';
import { useNavigate } from 'react-router-dom';
import Meta from '../components/Meta';
import { Navbar } from '../components/Navbar';
import Footer from '../components/Footer';
import { toast } from 'react-toastify';
import CustomModal from '../components/CustomModal';
import CustomModalUpdate from '../components/CustomModalUpdate';
import CustomModalDelete from '../components/CustomModalDelete';
import { useGetAgentOrdersQuery } from '../features/agents/agentsApiSlice';
import { useGetAgentsQuery } from '../features/agents/agentsApiSlice';
import { useUpdateOrderStatusMutation } from '../features/agents/agentsApiSlice';

const ApproveOrderPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const agnt = JSON.parse(localStorage.getItem('agent'));
  const aid = localStorage.getItem('aid');

  const [showw, setShoww] = useState(false);
  const [showww, setShowww] = useState(false);
  const [showSpina, setShowSpina] = useState(false);
  const [name, setName] = useState(null);

  // let order;

  let content;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [
    updateOrderStatus,
    { isSuccess: orderSuccess, isLoading: orderLoading, error: orderError },
  ] = useUpdateOrderStatusMutation();

  const { data, isSuccess, isLoading, error } = useGetAgentOrdersQuery(
    'orderList',
    {
      pollingInterval: 15000,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const { agent } = useGetAgentsQuery('agentsList', {
    selectFromResult: ({ data }) => ({
      agent: data?.entities[aid],
    }),
  });

  if (isSuccess) {
    // console.log(order);
    let order = data?.agentOrder.find((odi) => {
      return odi?._id === id;
    });
    content = (
      <>
        <Meta title="Edit-product-page" />
        <Navbar />
        <section className="home-wrapper container-fluid py-1">
          <div className="row">
            <div className="col-12 col-md-12 col-sm-12">
              <form
                className="d-flex  flex-column align-items-center"
                id="form"
                onSubmit={(e) => e.preventDefault()}
              >
                <h3 className="mb-4 title">Order Details</h3>

                <div>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Order Number:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.orderNumber}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Name:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.user?.firstname} {order?.user?.lastname}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    phone no:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.user?.mobile}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    company:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.user?.company}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    department:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.user?.department}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Employment Category:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.user?.employmentCategory}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Salary Range:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.user?.salaryRange}{' '}
                    </span>
                  </p>

                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Item:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.product?.title}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    price:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      GHS {order?.totalPrice}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Order Status:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.orderStatus}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Payment Plan:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.paymentPlan}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Payment duration:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {order?.paymentDuration}{' '}
                    </span>
                  </p>
                  <p
                    style={{
                      color: 'black',
                    }}
                  >
                    Initial Payment:{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      GHS {order?.initialPayment}{' '}
                    </span>
                  </p>
                  {order?.secondPayment > 0 && (
                    <p
                      style={{
                        color: 'black',
                      }}
                    >
                      Second Payment:{' '}
                      <span style={{ fontWeight: 'Bold' }}>
                        GHS {order?.secondPayment}{' '}
                      </span>
                    </p>
                  )}
                  {order?.thirdPayment > 0 && (
                    <p
                      style={{
                        color: 'black',
                      }}
                    >
                      Third Payment:{' '}
                      <span style={{ fontWeight: 'Bold' }}>
                        GHS {order?.thirdPayment}{' '}
                      </span>
                    </p>
                  )}
                  {order?.fourthPayment > 0 && (
                    <p
                      style={{
                        color: 'black',
                      }}
                    >
                      Fourth Payment:{' '}
                      <span style={{ fontWeight: 'Bold' }}>
                        GHS {order?.fourthPayment}{' '}
                      </span>
                    </p>
                  )}
                  {order?.fifthPayment > 0 && (
                    <p
                      style={{
                        color: 'black',
                      }}
                    >
                      Fifth Payment:{' '}
                      <span style={{ fontWeight: 'Bold' }}>
                        GHS {order?.fifthPayment}{' '}
                      </span>
                    </p>
                  )}
                  {order?.sixthPayment > 0 && (
                    <p
                      style={{
                        color: 'black',
                      }}
                    >
                      Sixth Payment:{' '}
                      <span style={{ fontWeight: 'Bold' }}>
                        GHS {order?.sixthPayment}{' '}
                      </span>
                    </p>
                  )}
                </div>

                <div className="d-flex gap-20 justify-content-between">
                  <div style={{ marginRight: '5rem' }}>
                    <CustomModalUpdate showw={showw} id={id} order={order} />
                  </div>
                  <div>
                    <CustomModalDelete showww={showww} id={id} order={order} />
                  </div>
                </div>

                {showSpina && <SpinerComponent />}
              </form>
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }

  if (isLoading) {
    content = (
      <>
        <Meta title="Approve-order-page" />
        <Navbar />
        <p>Loading...</p>
        <Footer />
      </>
    );
  }

  if (error) {
    content = (
      <>
        <Meta title="Approve-order-page" />
        <Navbar />
        <p>Something went wrong...</p>
        <Footer />
      </>
    );
  }

  return content;
};

export default ApproveOrderPage;
