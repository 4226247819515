/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLogoutMutation } from '../features/auth/authApiSlice';
import SpinerComponent from '../components/SpinerComponent';
import { toast } from 'react-toastify';
import './Navbar.css';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useGetAgentsQuery } from '../features/agents/agentsApiSlice';
import { AiOutlineClose } from 'react-icons/ai';

export const Navbar = ({
  handleShowAgentAccDetails,
  handleShowAllOrders,
  handleShowPendingOrders,
}) => {
  const [Logout, { isLoading, isSuccess, error }] = useLogoutMutation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showSpina, setShowSpina] = useState(false);
  const [showmail, setShowMail] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const aid = localStorage.getItem('aid');

  const { agent } = useGetAgentsQuery('agentsList', {
    selectFromResult: ({ data }) => ({
      agent: data?.entities[aid],
    }),
  });

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }
    if (isSuccess) {
      setShowSpina(false);
      navigate('/');
    }

    if (error) {
      setShowSpina(false);
      toast.error('Something went wrong!');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, error]);

  let collapsedNav = null;

  if (pathname === '/agent-main-page') {
    collapsedNav = (
      <>
        <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
          {!menuOpen && (
            <>
              <span></span>
              <span></span>
              <span></span>
            </>
          )}

          {menuOpen && (
            <div style={{ marginRight: '4rem' }}>
              <AiOutlineClose size={50} color="black" />
            </div>
          )}
        </div>
        <ul className={menuOpen ? 'open' : ''}>
          <li>
            {agent && (
              <h4
                style={{
                  color: 'black',
                  marginRight: '2rem',
                  marginTop: '0.5rem',
                  textOverflow: 'ellipsis',
                }}
              >
                Welcome, {agent?.firstname}!
              </h4>
            )}
            <NavLink
              onClick={() => {
                handleShowAllOrders();
                setMenuOpen(false);
              }}
              className="dontshow"
            >
              All Orders
            </NavLink>
            <NavLink
              onClick={() => {
                handleShowPendingOrders();
                setMenuOpen(false);
              }}
              className="dontshow"
            >
              Pending Orders
            </NavLink>

            <NavLink
              onClick={() => handleShowAgentAccDetails()}
              className="dontshow"
            >
              Account Details
            </NavLink>
            <NavLink onClick={() => Logout()}>Logout</NavLink>
          </li>
        </ul>
      </>
    );
  }

  return (
    <nav>
      <Link to="/agent-main-page" className="title" style={{ height: '50px' }}>
        <img
          src="/images/wnshupm.png"
          style={{
            objectFit: 'fill',
            height: '100%',
            width: '100%',
          }}
          alt="brand"
        />
      </Link>
      {collapsedNav}
      {showSpina && <SpinerComponent />}
    </nav>
  );
};
