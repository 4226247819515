/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import SpinerComponent from '../components/SpinerComponent';
import { useUpdateOrderStatusMutation } from '../features/agents/agentsApiSlice';
import { useGetAgentOrdersQuery } from '../features/agents/agentsApiSlice';

const CustomModalUpdate = ({ showw, id, order }) => {
  const navigate = useNavigate();
  const [showSpina, setShowSpina] = useState(false);
  const [show, setShow] = useState(false);
  let orde;

  const status = 'APPROVED';
  const [
    updateOrderStatus,
    { isSuccess: orderSuccess, isLoading: orderLoading, error: orderError },
  ] = useUpdateOrderStatusMutation();
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const { data } = useGetAgentOrdersQuery('orderList', {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  orde = data?.agentOrder.find((odi) => {
    return odi?._id === id;
  });

  useEffect(() => {
    if (showw) {
      setShow(false);
    }
  }, [showw]);

  const sendEmailToVendor = async () => {
    try {
      const email = order?.product?.vendorEmail;
      const orderNumber = order?.orderNumber;
      const BusinessName = order?.product?.vendor?.BusinessName;
      const formData = new FormData();
      formData.append('orderNumber', orderNumber);
      formData.append('email', email);
      formData.append('BusinessName', BusinessName);
      const urlEncoded = new URLSearchParams(formData).toString();
      await fetch(
        'https://worknshup-agent-backend-029506a667cd.herokuapp.com/api/agent/send-email-order-approved',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
          },
          body: urlEncoded,
        }
      ).catch((err) => console.log(`Error occured: ${err}`));
    } catch (err) {
      console.log('error: email not sent!');
    }
  };
  const sendEmailToUser = async () => {
    try {
      const email = order?.user?.email;
      const orderNumber = order?.orderNumber;
      const firstname = order?.user?.firstname;
      const formData = new FormData();
      formData.append('orderNumber', orderNumber);
      formData.append('email', email);
      formData.append('firstname', firstname);
      const urlEncoded = new URLSearchParams(formData).toString();
      await fetch(
        'https://worknshup-agent-backend-029506a667cd.herokuapp.com/api/agent/send-email-user-order-approved',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
          },
          body: urlEncoded,
        }
      ).catch((err) => console.log(`Error occured: ${err}`));
    } catch (err) {
      console.log('error: email not sent!');
    }
  };

  useEffect(() => {
    if (orderLoading) {
      setShowSpina(true);
    }
    if (orderSuccess) {
      sendEmailToVendor();
      sendEmailToUser();
      setShowSpina(false);
      toast.success('Order approved!');
      handleClose();
      navigate('/agent-main-page');
    }
    if (orderError) {
      handleClose();
      setShowSpina(false);
      toast.error('Something went wrong!');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSuccess, orderLoading, orderError]);

  const handleApprove = async () => {
    try {
      await updateOrderStatus({ id, status });
    } catch (err) {}

    handleClose();
  };

  return (
    <>
      <button
        onClick={handleShow}
        className="btn btn-success border-0 rounded-3 mt-5"
        style={{ backgroundColor: 'green' }}
      >
        Approve
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>APPROVE THIS ORDER?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are You Sure You Want To Approve This Order?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: 'green' }}
            onClick={() => handleApprove()}
            variant="primary"
          >
            APPROVE
          </Button>
        </Modal.Footer>
      </Modal>
      {showSpina && <SpinerComponent />}
    </>
  );
};

export default CustomModalUpdate;
