import React from 'react';
import Meta from '../components/Meta';
import BreadCrumb from '../components/BreadCrumb';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SpinerComponent from '../components/SpinerComponent';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useResetPasswordMutation } from '../features/auth/authApiSlice';
import CustomInputy from '../components/CustomInputTwo';

let schema = Yup.object().shape({
  password: Yup.string().required('password is required'),
  confirm_password: Yup.string()
    .label('confirm password')
    .required('please confirm password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const ResetPassword = () => {
  const { token } = useParams();
  const [showSpina, setShowSpina] = useState(false);
  const navigate = useNavigate();

  const [resetPassword, { isLoading, isSuccess, error }] =
    useResetPasswordMutation();

  useEffect(() => {
    if (isLoading) {
      setShowSpina(true);
    }
    if (isSuccess) {
      setShowSpina(false);
      toast.success('Password reset successful!');
      // navigate(-1);
      navigate('/');
    }

    if (error) {
      setShowSpina(false);
      toast.error('Something went wrong!');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isLoading, error]);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const { password } = values;
      await resetPassword({ password, token });
      try {
      } catch (error) {
        throw new Error(error);
      }
    },
  });

  return (
    <>
      <Meta title="Reset Password" />
      <BreadCrumb title="Reset Password" />

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="">
              <h3 className="text-center mb-3">Reset Password</h3>
              <form
                action=""
                onSubmit={formik.handleSubmit}
                className="d-flex flex-column align-items-center gap-15"
              >
                <div>
                  <CustomInputy
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="forgot-password-input"
                    val={formik.values.password}
                    onCh={formik.handleChange('password')}
                    onBl={formik.handleBlur('password')}
                    style={{ color: 'black' }}
                  />
                </div>
                <div className="error">
                  {formik.touched.password && formik.errors.password ? (
                    <div>{formik.errors.password}</div>
                  ) : null}
                </div>
                <div>
                  <CustomInputy
                    type="password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    className="forgot-password-input"
                    val={formik.values.confirm_password}
                    onCh={formik.handleChange('confirm_password')}
                    style={{ color: 'black' }}
                  />
                </div>
                <div className="error">
                  {formik.touched.confirm_password &&
                  formik.errors.confirm_password ? (
                    <div>{formik.errors.confirm_password}</div>
                  ) : null}
                </div>
                <div>
                  <div className="d-flex mt-3 justify-content-center align-items-center gap-15">
                    <button className="button border-0" type="submit">
                      Reset
                    </button>
                  </div>
                </div>
                {showSpina && <SpinerComponent />}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
