/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import { IoPersonSharp } from 'react-icons/io5';
import { useNavigate, Link } from 'react-router-dom';
import Meta from '../components/Meta';
import 'react-quill/dist/quill.snow.css';
import AgentOrders from '../components/AgentOrders';
import AgentPendingOrders from '../components/AgentPendingOrders';
import AgentAccountDetails from '../components/AgentAccountDetails';
import { useGetAgentsQuery } from '../features/agents/agentsApiSlice';
import { Navbar } from '../components/Navbar';

const MainPage = () => {
  const agnt = JSON.parse(localStorage.getItem('agent'));
  const email = localStorage.getItem('agmail');
  const id = localStorage.getItem('aid');
  const [showAgentOrdersForm, setShowAgentOrdersForm] = useState(true);
  const [showAgentPendingOrders, setShowAgentPendingOrders] = useState(false);
  const [showAgentAccDetails, setShowAgentAccDetails] = useState(false);
  const navigate = useNavigate();

  let idx = null;

  const { agent } = useGetAgentsQuery('agentsList', {
    selectFromResult: ({ data }) => ({
      agent: data?.entities[id],
    }),
  });

  const {
    data,
    isSuccess: agentSuc,
    isLoading: agentLoad,
    error: agentErr,
  } = useGetAgentsQuery('agentList', {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (agnt === null) {
      navigate('/');
    }

    setShowAgentOrdersForm(true);
    setShowAgentPendingOrders(false);
    setShowAgentAccDetails(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (agnt === null) {
      navigate('/');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agnt]);
  if (agentSuc) {
    if (!agent?.approved) {
      navigate('/');
    }
    const { ids, entities } = data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //eslint-disable-next-line react-hooks/exhaustive-deps
    idx = ids.find((idx) => entities[idx]?.email === email);
    if (!entities[idx]?.registrationCompleted) {
      navigate('/incomplete-reg');
    }
  }

  const handleShowPendingOrders = () => {
    setShowAgentPendingOrders(true);
    setShowAgentOrdersForm(false);
    setShowAgentAccDetails(false);
  };
  const handleShowAllOrders = () => {
    setShowAgentOrdersForm(true);
    setShowAgentPendingOrders(false);
    setShowAgentAccDetails(false);
  };
  const handleShowAgentAccDetails = () => {
    setShowAgentAccDetails(true);
    setShowAgentOrdersForm(false);
    setShowAgentPendingOrders(false);
  };

  return (
    <>
      <Meta title="agent Page" />
      <Navbar
        handleShowAgentAccDetails={handleShowAgentAccDetails}
        handleShowAllOrders={handleShowAllOrders}
        handleShowPendingOrders={handleShowPendingOrders}
      />

      <section className="home-wrapper p-0 m-0">
        <div className="container-fluid py-1 ">
          <div className="row">
            <div className="col-3 col-sm-3 col-md-3 colapsy">
              <div className="contact-inner d-flex flex-column">
                <Link
                  onClick={() => handleShowAllOrders()}
                  style={{ textDecoration: 'none' }}
                >
                  <div className=" useracc1 w-100 py-2">
                    <IoPersonSharp
                      className="fs-1"
                      style={{
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        padding: '0.2rem',
                      }}
                    />
                    <p className=" mt-1">All Orders</p>
                  </div>
                </Link>

                <Link
                  onClick={() => handleShowPendingOrders()}
                  style={{ textDecoration: 'none' }}
                >
                  <div className=" useracc2 w-100 py-2">
                    <IoPersonSharp
                      className="fs-1"
                      style={{
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        padding: '0.2rem',
                      }}
                    />
                    <p>Pending Orders</p>
                  </div>
                </Link>
                <Link
                  onClick={() => handleShowAgentAccDetails()}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="py-2 useracc2 w-100">
                    <IoPersonSharp
                      className="fs-1"
                      style={{
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        padding: '0.2rem',
                      }}
                    />
                    <p>My Account</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-9 strechy">
              {showAgentOrdersForm && <AgentOrders />}
              {showAgentPendingOrders && <AgentPendingOrders />}
              {showAgentAccDetails && <AgentAccountDetails agent={agent} />}
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default MainPage;
