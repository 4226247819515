import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';
import { apiSlice } from '../../app/api/apiSlice';

const agentsAdapter = createEntityAdapter({});

const initialState = agentsAdapter.getInitialState();

export const agentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAgents: builder.query({
      query: () => ({
        url: '/agent/all-agents',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedagents = responseData.map((agent) => {
          agent.id = agent._id;
          return agent;
        });
        return agentsAdapter.setAll(initialState, loadedagents);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: 'agent', id: 'LIST' },
            ...result.ids.map((id) => ({ type: 'agent', id })),
          ];
        } else return [{ type: 'agent', id: 'LIST' }];
      },
    }),
    getAgentOrders: builder.query({
      query: () => ({
        url: '/agent/get-agent-orders',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: 'agent', id: 'LIST' },
            ...result.ids.map((id) => ({ type: 'agent', id })),
          ];
        } else return [{ type: 'agent', id: 'LIST' }];
      },
    }),
    addNewAgent: builder.mutation({
      query: (initialagentData) => ({
        url: '/agent/register',
        method: 'POST',
        body: {
          ...initialagentData,
        },
      }),
      invalidatesTags: [{ type: 'agent', id: 'LIST' }],
    }),
    updateAgent: builder.mutation({
      query: (initialagentData) => ({
        url: '/agent/update-agent',
        method: 'PATCH',
        body: {
          ...initialagentData,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'agent', id: arg.id }],
    }),
    updateOrderStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/agent/update-order-status/${id}`,
        method: 'PATCH',
        body: {
          status,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'agent', id: arg.id }],
    }),
    deleteAgent: builder.mutation({
      query: ({ id }) => ({
        url: `/agents`,
        method: 'DELETE',
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'agent', id: arg.id }],
    }),
  }),
});

export const {
  useGetAgentsQuery,
  useGetAgentOrdersQuery,
  useAddNewAgentMutation,
  useDeleteAgentMutation,
  useUpdateAgentMutation,
  useUpdateOrderStatusMutation,
} = agentsApiSlice;

// returns the query result object
export const selectAgentsResult = agentsApiSlice.endpoints.getAgents.select();

// returns the query result object for Orders
export const selectAgentsOrdersResult =
  agentsApiSlice.endpoints.getAgentOrders.select();

// creates memoized selector
const selectAgentsData = createSelector(
  selectAgentsResult,
  (agentsResult) => agentsResult.data // normalized state object with ids & entities
);
// creates memoized selector for agent orders
const selectAgentsOrderData = createSelector(
  selectAgentsOrdersResult,
  (agentsResult) => agentsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllagents,
  selectById: selectAgentById,
  selectIds: selectAgentIds,
  // Pass in a selector that returns the agents slice of state
} = agentsAdapter.getSelectors(
  (state) => selectAgentsData(state) ?? initialState
);
//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllagentsOrders,
  selectById: selectAgentOrdersById,
  selectIds: selectAgentOrdersIds,
  // Pass in a selector that returns the agents slice of state
} = agentsAdapter.getSelectors(
  (state) => selectAgentsOrderData(state) ?? initialState
);
