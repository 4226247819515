import React, { useEffect, useState } from 'react';
import CustomInputy from '../components/CustomInputTwo';
import { useParams } from 'react-router-dom';
import SpinerComponent from '../components/SpinerComponent';
import { useNavigate } from 'react-router-dom';
import Meta from '../components/Meta';
import { Navbar } from '../components/Navbar';
import Footer from '../components/Footer';
import { toast } from 'react-toastify';
import { useUpdateAgentMutation } from '../features/agents/agentsApiSlice';
import { useGetAgentsQuery } from '../features/agents/agentsApiSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';

let schema = Yup.object().shape({
  firstname: Yup.string(),
  lastname: Yup.string(),
  mobile: Yup.string(),
  company: Yup.string(),
  department: Yup.string(),
  email: Yup.string(),
  password: Yup.string(),
  confirm_password: Yup.string()
    .label('confirm password')
    .required('please confirm password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const EditAgentPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showSpina, setShowSpina] = useState(false);
  const { Agent } = useGetAgentsQuery('agentsList', {
    selectFromResult: ({ data, isLoading, isSuccess }) => ({
      Agent: data?.entities[id],
      isLoading,
      isSuccess,
    }),
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [
    updateAgent,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      error: updateError,
    },
  ] = useUpdateAgentMutation();

  useEffect(() => {
    if (isUpdateLoading) {
      setShowSpina(true);
    }

    if (updateError) {
      setShowSpina(false);
      toast.error('Something went wrong');
    }

    if (isUpdateSuccess) {
      setShowSpina(false);
      toast.success('update success!');
      setTimeout(() => {
        setShowSpina(false);
      }, 2000);
      navigate('/agent-main-page');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateLoading, isUpdateSuccess, updateError]);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
      firstname: Agent?.firstname,
      lastname: Agent?.lastname,
      mobile: Agent?.mobile,
      email: Agent?.email,
      company: Agent?.company,
      department: Agent?.department,
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      const {
        password,
        firstname,
        lastname,
        email,
        mobile,
        company,
        department,
      } = values;

      try {
        await updateAgent({
          firstname,
          lastname,
          email,
          password,
          mobile,
          company,
          department,

          id,
        });
      } catch (error) {
        setTimeout(() => {
          setShowSpina(false);
        }, 2000);
      }
    },
  });

  return (
    <>
      <Meta title="Edit-Agent-page" />
      <Navbar />
      <section className="home-wrapper p-0 m-0">
        <div className="container-fluid py-1 ">
          <div className="row">
            <div className="col-12">
              <form
                className="d-flex  flex-column align-items-center"
                id="form"
                onSubmit={formik.handleSubmit}
              >
                <h3 className="mb-4 title">Edit Agent Details</h3>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                  >
                    First Name
                  </p>
                  <CustomInputy
                    type="text"
                    label="Enter First Name"
                    name="title"
                    val={formik.values.firstname}
                    onCh={formik.handleChange('firstname')}
                  />
                </div>
                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                  >
                    Last Name
                  </p>
                  <CustomInputy
                    type="text"
                    label="Enter last Name"
                    name="title"
                    val={formik.values.lastname}
                    onCh={formik.handleChange('lastname')}
                  />
                </div>

                <div className="mt-3">
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    email
                  </p>
                  <CustomInputy
                    type="text"
                    label="Enter Agent Email"
                    name="email"
                    val={formik.values.email}
                    onCh={formik.handleChange('email')}
                  />
                </div>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    Password
                  </p>
                  <CustomInputy
                    type="password"
                    label="Enter New Password"
                    name="password"
                    val={formik.values.password}
                    onCh={formik.handleChange('password')}
                  />

                  <CustomInputy
                    type="password"
                    name="confirm_password"
                    label="confirm Password"
                    id="confirm_pass"
                    val={formik.values.confirm_password}
                    onCh={formik.handleChange('confirm_password')}
                  />
                  <div className="error">
                    {formik.touched.confirm_password &&
                    formik.errors.confirm_password ? (
                      <div>{formik.errors.confirm_password}</div>
                    ) : null}
                  </div>
                </div>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    Mobile Number
                  </p>

                  <CustomInputy
                    type="text"
                    label="Enter Mobile No"
                    name="mobile"
                    val={formik.values.mobile}
                    onCh={formik.handleChange('mobile')}
                  />
                </div>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    Company
                  </p>

                  <CustomInputy
                    type="text"
                    label="Enter Your Company"
                    name="company"
                    val={formik.values.company}
                    onCh={formik.handleChange('company')}
                  />
                </div>

                <div>
                  <p
                    style={{
                      color: 'red',
                    }}
                    className="mt-3"
                  >
                    Department
                  </p>
                  <CustomInputy
                    type="text"
                    name="department"
                    val={formik.values.department}
                    onCh={formik.handleChange('department')}
                    label="Enter Your department"
                    className="mb-3"
                  />
                </div>

                <div className="d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-success border-0 rounded-3 mt-5"
                    style={{ marginRight: '5rem' }}
                  >
                    Update
                  </button>
                </div>

                {showSpina && <SpinerComponent />}
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default EditAgentPage;
