import React from 'react';

const CustomInput = (props) => {
  const { type, name, label, val, onCh, onBl } = props;

  return (
    <div className="formy mt-3">
      <input
        type={type}
        placeholder={label}
        className={'form-control'}
        name={name}
        value={val}
        onChange={onCh}
        onBlur={onBl}
      />
    </div>
  );
};

export default CustomInput;
