import React from 'react';
import { Link } from 'react-router-dom';

const AgentAccountDetails = ({ agent }) => {
  return (
    <>
      <div className="container-fluid">
        <div className="border-bottom w-100">
          <h3 className="contact-title mb-4">Account Overview</h3>
        </div>
        <div className="row my-2">
          <div className="col-4 my-2 mx-1 acc-det1">
            <h6>First Name:</h6>
            <p className="">{agent?.firstname}</p>
          </div>
          <div className="col-4 my-2 mx-1 acc-det1">
            <h6>Account Password:</h6>
            <p className="">*******</p>
          </div>
          <div className="col-4 my-2 mx-1 acc-det1">
            <h6>Account Email:</h6>
            <p className="">{agent?.email}</p>
          </div>
        </div>
        <div className="row my-2 ">
          <div className="col-4 my-2 mx-1 acc-det1">
            <h6>Phone Number:</h6>
            <p className="">{agent?.mobile}</p>
          </div>
          <div className="col-4 my-2 mx-1 acc-det1">
            <h6>Company:</h6>
            <p className="">{agent?.company}</p>
          </div>
          <div className="col-4 my-2 mx-1 acc-det1">
            <h6>department:</h6>
            <p className="">{agent?.department}</p>
          </div>
        </div>
        <div>
          <Link to={`/edit-agent/${agent?._id}`}>edit</Link>
        </div>
      </div>
    </>
  );
};

export default AgentAccountDetails;
